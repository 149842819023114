import React from 'react'
import styled from 'styled-components'

// Components
import ParseContent from 'components/shared/ParseContent'

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.primary};
`

interface GooglemapsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Googlemaps
}

const Googlemaps = ({ fields }: GooglemapsProps) => (
  <section>
    <Content content={fields.description} className="mb-5" />
    <iframe
      title="GTS Online"
      src={fields.url}
      width="100%"
      height={571}
      style={{ border: 0 }}
      allowFullScreen
      loading="lazy"
    />
  </section>
)

export default Googlemaps
